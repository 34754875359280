@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
	box-sizing: border-box;
	margin: 0;
}

body {
	margin: 0;
	font: 400 16px 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button {
	font: 500 14px 'Roboto', sans-serif;
	letter-spacing: 1.15px;
	cursor: pointer;
}