.component-cropping {
    height: 100%;
  }
  .component-standalone-filtering {
    background-color: #f7f9fa;
    height: 100%;
    overflow-y: auto;
  }
  .component-standalone-filtering .content {
    margin: 8px;
  }
  .component-standalone-filtering .image {
    max-height: 100vh;
    max-width: 100vw;
  }
  .component-imageResults, .component-imageDetail, .component-cropping {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    background-color: #f7f9fa;
  }
  .component-imageResults .resultsGridContainer, .component-imageDetail .resultsGridContainer, .component-cropping .resultsGridContainer {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    flex: 1;
  }
  .component-imageResults .resultsGridContainer .resultsGrid, .component-imageDetail .resultsGridContainer .resultsGrid, .component-cropping .resultsGridContainer .resultsGrid {
    margin: 16px !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    background-color: white;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
    gap: 8px;
  }
  @media only screen and (min-width: 600px) {
    .component-imageResults .resultsGridContainer .resultsGrid, .component-imageDetail .resultsGridContainer .resultsGrid, .component-cropping .resultsGridContainer .resultsGrid {
      margin: 24px !important;
    }
  }
  .component-imageResults .resultsGridContainer .resultsGrid_tile, .component-imageDetail .resultsGridContainer .resultsGrid_tile, .component-cropping .resultsGridContainer .resultsGrid_tile {
    cursor: pointer;
    height: 250px;
  }
  .component-imageResults .resultsGridContainer .resultsGrid_image, .component-imageDetail .resultsGridContainer .resultsGrid_image, .component-cropping .resultsGridContainer .resultsGrid_image {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: rgba(50, 50, 50, 0.1);
  }
  .component-imageResults .imageDetailContainer, .component-imageDetail .imageDetailContainer, .component-cropping .imageDetailContainer {
    max-width: 1140px;
    margin: 16px;
    display: flex;
    align-items: center;
    flex: 1;
  }
  .component-imageResults .imageDetailContainer .imageDetail, .component-imageDetail .imageDetailContainer .imageDetail, .component-cropping .imageDetailContainer .imageDetail {
    max-width: 100%;
    max-height: 70vh;
    object-fit: contain;
    padding: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    background-color: white;
  }
  .component-imageResults .cropping, .component-imageDetail .cropping, .component-cropping .cropping {
    max-width: 1140px;
    width: 100%;
    flex: 1;
  }
  .component-imageResults .bottomBar, .component-imageDetail .bottomBar, .component-cropping .bottomBar {
    height: 90px;
    background-color: #c8193c;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: auto;
  }
  .component-imageResults .bottomBar :first-child, .component-imageDetail .bottomBar :first-child, .component-cropping .bottomBar :first-child {
    margin-left: 5%;
  }
  .component-imageResults .bottomBar_button, .component-imageDetail .bottomBar_button, .component-cropping .bottomBar_button {
    background-color: transparent;
    border: none;
    color: white;
  }
  .component-imageResults .bottomBar_button-right, .component-imageDetail .bottomBar_button-right, .component-cropping .bottomBar_button-right {
    margin-left: auto;
    margin-right: 5%;
  }
  