.header {
	background-color: #c8193c;
	width: 100%;
	padding: 32px 0;
	display: flex;
	justify-content: center;
	position: relative;
  }
  .header .backButton {
	background-color: transparent;
	border: none;
	color: white;
	font-size: 20px;
	cursor: pointer;
	position: absolute;
	left: 5%;
  }
  .header .logo {
	cursor: pointer;
	height: 38px;
  }
  .component-mainMenu {
	background-color: #f7f9fa;
	height: 100%;
  }
  .component-mainMenu .header {
	background-color: #c8193c;
	width: 100%;
	padding: 26px 0;
	display: flex;
	justify-content: center;
  }
  
  .component-mainMenu .alertContainer {
	padding: 20px;
	background-color: white;
  }
  .component-mainMenu .alertContainer .alert {
	font-size: 14px;
	font-weight: normal;
	line-height: 1.43;
	letter-spacing: 0.25px;
	color: #6e7375;
	max-width: 1140px;
	margin: 0 auto;
  }
  .component-mainMenu .alertContainer .alertButtonContainer {
	display: flex;
	justify-content: flex-end;
	padding-top: 16px;
	max-width: 1140px;
	margin: 0 auto;
  }
  .component-mainMenu .alertContainer .alertButtonContainer .alertButton {
	float: right;
	color: #c8193c;
	border: none;
	background-color: transparent;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.14;
	letter-spacing: 1.25px;
	font-family: 'Roboto', sans-serif !important;
  }
  .component-mainMenu .contentContainer {
	padding: 20px;
  }
  .component-mainMenu .contentContainer .footer {
	font-size: 12px;
	line-height: 1.33;
	letter-spacing: 0.4px;
	text-align: center;
	color: #6e7375;
  }
  