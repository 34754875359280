.component-mainMenu .sectionContainer {
	max-width: 1140px;
	background-color: white;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
	padding: 16px 16px 0px 16px;
	margin: 0 auto 24px auto;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer {
	  padding: 24px 24px 0px 24px;
	}
  }
  .component-mainMenu .sectionContainer .titleContainer {
	display: flex;
  }
  .component-mainMenu .sectionContainer .titleContainer .icon {
	width: 14px;
	height: 14px;
	object-fit: contain;
	margin-right: 8px;
  }
  .component-mainMenu .sectionContainer .titleContainer .title {
	font-size: 10px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 1.5px;
	color: #939799;
  }
  .component-mainMenu .sectionContainer .cardsContainer {
	display: flex;
	flex-direction: column;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer {
	  display: grid;
	  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
  }
  .component-mainMenu .sectionContainer .cardsContainer > .card:not(:last-child) {
	border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  }
  .component-mainMenu .sectionContainer .cardsContainer .card {
	display: flex;
	padding: 16px 0;
	cursor: pointer;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer .card {
	  flex-direction: column;
	  align-items: center;
	  text-align: center;
	}
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .imageContainer {
	position: relative;
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .imageContainer .image {
	height: 50px;
	object-fit: contain;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer .card .imageContainer .image {
	  height: 115px;
	  margin-bottom: 8px;
	}
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .imageContainer .tooltip {
	position: absolute;
	right: -5px;
	top: -5px;
	font-size: 10px;
	font-weight: 500;
	line-height: 1.6;
	background-color: #c8193c;
	border-radius: 50%;
	width: 15px;
	height: 15px;
	text-align: center;
	color: white;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer .card .imageContainer .tooltip {
	  width: 32px;
	  height: 32px;
	  font-size: 20px;
	}
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .textContainer {
	margin-left: 20px;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer .card .textContainer {
	  margin-left: 0;
	}
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .textContainer .title {
	font-size: 16px;
	line-height: 1.5;
	font-weight: normal;
	letter-spacing: 0.5px;
	color: rgba(0, 0, 0, 0.87);
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .textContainer .description {
	font-size: 14px;
	line-height: 1.43;
	letter-spacing: 0.25px;
	color: rgba(0, 0, 0, 0.6);
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer .card .textContainer .description {
	  padding: 0 8px;
	}
  }
  .component-mainMenu .sectionContainer .linksContainer .link {
	font-size: 16px;
	line-height: 1.5;
	font-weight: normal;
	letter-spacing: 0.5px;
	color: rgba(0, 0, 0, 0.87);
	padding: 12px 0;
	text-decoration: none;
	display: block;
  }
  .component-mainMenu .sectionContainer .linksContainer > .link:not(:last-child) {
	border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  }
  