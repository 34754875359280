html, body, #root, .component-carousel, .slider, .slider-frame, .slider-list, .slider-slide, .root, .swiperContainer, .innerContainer {
	height: 100% !important;
  }
  .component-carousel {
	color: white;
  }
  .component-carousel .innerContainer {
	text-align: center;
	max-width: 690px;
	margin: 0 auto;
	padding: 40px 0;
  }
  .component-carousel .innerContainer > div:nth-child(2) {
	position: absolute;
	padding: 0 20px;
	max-width: 690px;
  }
  @media only screen and (max-height: 569px) {
	.component-carousel .innerContainer > div:nth-child(2) {
	  bottom: 11%;
	}
  }
  @media only screen and (min-height: 570px) and (max-height: 812px) {
	.component-carousel .innerContainer > div:nth-child(2) {
	  bottom: 13%;
	}
  }
  @media only screen and (min-height: 813px) {
	.component-carousel .innerContainer > div:nth-child(2) {
	  bottom: 15%;
	}
  }
  .component-carousel .logo {
	margin-bottom: 40px;
  }
  @media only screen and (min-width: 600px) {
	.component-carousel .logo {
	  margin-bottom: 40px;
	  width: 250px;
	}
  }
  .component-carousel .illustration {
	width: 75%;
	margin-bottom: 5%;
	object-fit: contain;
	max-height: 300px;
  }
  @media only screen and (min-height: 800px) {
	.component-carousel .illustration {
	  max-height: 550px;
	}
  }
  @media only screen and (min-height: 1024px) {
	.component-carousel .illustration {
	  margin-top: 5%;
	}
  }
  .component-carousel .title {
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.18px;
	margin-bottom: 14px;
	font-weight: 400;
  }
  @media only screen and (min-width: 600px) {
	.component-carousel .title {
	  font-size: 34px;
	  line-height: 36px;
	}
  }
  .component-carousel .description {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
	opacity: 0.7;
	padding: 0 5px;
  }
  @media only screen and (min-width: 600px) {
	.component-carousel .description {
	  font-size: 16px;
	  line-height: 24px;
	}
  }
  .component-carousel .swiperContainer {
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
  }
  .component-carousel .swiper {
	right: 0;
	top: 0;
	height: 100%;
  }
  .component-carousel .nextButton {
	top: 55%;
	right: 15%;
	position: absolute;
	width: 60%;
  }
  .listContainer {
	position: absolute;
	display: flex;
	width: 100%;
	justify-content: center;
	bottom: 4%;
  }
  .listContainer .list {
	list-style-type: none;
	display: flex;
	padding: 0;
  }
  .listContainer .list .listItem {
	border: 0.5px solid;
	border-radius: 50%;
	background-color: transparent;
	padding: 5px;
	vertical-align: middle;
	cursor: pointer;
  }
  .listContainer .link {
	position: absolute;
	right: 4%;
	text-decoration: none;
	padding-right: 10px;
  }
  