.component-filter-dialog .app-bar {
	background-color: #c8193c;
  }
  .component-filter-dialog .title {
	flex: 1;
  }
  .cover {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: transparent;
  }
  .toast-style {
	white-space: pre-wrap;
	word-break: break-all;
  }
  