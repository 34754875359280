@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
* {
	box-sizing: border-box;
	margin: 0;
}

body {
	margin: 0;
	font: 400 16px 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button {
	font: 500 14px 'Roboto', sans-serif;
	letter-spacing: 1.15px;
	cursor: pointer;
}
.component-cropping {
    height: 100%;
  }
  .component-standalone-filtering {
    background-color: #f7f9fa;
    height: 100%;
    overflow-y: auto;
  }
  .component-standalone-filtering .content {
    margin: 8px;
  }
  .component-standalone-filtering .image {
    max-height: 100vh;
    max-width: 100vw;
  }
  .component-imageResults, .component-imageDetail, .component-cropping {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    background-color: #f7f9fa;
  }
  .component-imageResults .resultsGridContainer, .component-imageDetail .resultsGridContainer, .component-cropping .resultsGridContainer {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    flex: 1 1;
  }
  .component-imageResults .resultsGridContainer .resultsGrid, .component-imageDetail .resultsGridContainer .resultsGrid, .component-cropping .resultsGridContainer .resultsGrid {
    margin: 16px !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    background-color: white;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
    grid-gap: 8px;
    gap: 8px;
  }
  @media only screen and (min-width: 600px) {
    .component-imageResults .resultsGridContainer .resultsGrid, .component-imageDetail .resultsGridContainer .resultsGrid, .component-cropping .resultsGridContainer .resultsGrid {
      margin: 24px !important;
    }
  }
  .component-imageResults .resultsGridContainer .resultsGrid_tile, .component-imageDetail .resultsGridContainer .resultsGrid_tile, .component-cropping .resultsGridContainer .resultsGrid_tile {
    cursor: pointer;
    height: 250px;
  }
  .component-imageResults .resultsGridContainer .resultsGrid_image, .component-imageDetail .resultsGridContainer .resultsGrid_image, .component-cropping .resultsGridContainer .resultsGrid_image {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: rgba(50, 50, 50, 0.1);
  }
  .component-imageResults .imageDetailContainer, .component-imageDetail .imageDetailContainer, .component-cropping .imageDetailContainer {
    max-width: 1140px;
    margin: 16px;
    display: flex;
    align-items: center;
    flex: 1 1;
  }
  .component-imageResults .imageDetailContainer .imageDetail, .component-imageDetail .imageDetailContainer .imageDetail, .component-cropping .imageDetailContainer .imageDetail {
    max-width: 100%;
    max-height: 70vh;
    object-fit: contain;
    padding: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    background-color: white;
  }
  .component-imageResults .cropping, .component-imageDetail .cropping, .component-cropping .cropping {
    max-width: 1140px;
    width: 100%;
    flex: 1 1;
  }
  .component-imageResults .bottomBar, .component-imageDetail .bottomBar, .component-cropping .bottomBar {
    height: 90px;
    background-color: #c8193c;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: auto;
  }
  .component-imageResults .bottomBar :first-child, .component-imageDetail .bottomBar :first-child, .component-cropping .bottomBar :first-child {
    margin-left: 5%;
  }
  .component-imageResults .bottomBar_button, .component-imageDetail .bottomBar_button, .component-cropping .bottomBar_button {
    background-color: transparent;
    border: none;
    color: white;
  }
  .component-imageResults .bottomBar_button-right, .component-imageDetail .bottomBar_button-right, .component-cropping .bottomBar_button-right {
    margin-left: auto;
    margin-right: 5%;
  }
  
.component-filter-dialog .app-bar {
	background-color: #c8193c;
  }
  .component-filter-dialog .title {
	flex: 1 1;
  }
  .cover {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: transparent;
  }
  .toast-style {
	white-space: pre-wrap;
	word-break: break-all;
  }
  
.header {
	background-color: #c8193c;
	width: 100%;
	padding: 32px 0;
	display: flex;
	justify-content: center;
	position: relative;
  }
  .header .backButton {
	background-color: transparent;
	border: none;
	color: white;
	font-size: 20px;
	cursor: pointer;
	position: absolute;
	left: 5%;
  }
  .header .logo {
	cursor: pointer;
	height: 38px;
  }
  .component-mainMenu {
	background-color: #f7f9fa;
	height: 100%;
  }
  .component-mainMenu .header {
	background-color: #c8193c;
	width: 100%;
	padding: 26px 0;
	display: flex;
	justify-content: center;
  }
  
  .component-mainMenu .alertContainer {
	padding: 20px;
	background-color: white;
  }
  .component-mainMenu .alertContainer .alert {
	font-size: 14px;
	font-weight: normal;
	line-height: 1.43;
	letter-spacing: 0.25px;
	color: #6e7375;
	max-width: 1140px;
	margin: 0 auto;
  }
  .component-mainMenu .alertContainer .alertButtonContainer {
	display: flex;
	justify-content: flex-end;
	padding-top: 16px;
	max-width: 1140px;
	margin: 0 auto;
  }
  .component-mainMenu .alertContainer .alertButtonContainer .alertButton {
	float: right;
	color: #c8193c;
	border: none;
	background-color: transparent;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.14;
	letter-spacing: 1.25px;
	font-family: 'Roboto', sans-serif !important;
  }
  .component-mainMenu .contentContainer {
	padding: 20px;
  }
  .component-mainMenu .contentContainer .footer {
	font-size: 12px;
	line-height: 1.33;
	letter-spacing: 0.4px;
	text-align: center;
	color: #6e7375;
  }
  
html, body, #root, .component-carousel, .slider, .slider-frame, .slider-list, .slider-slide, .root, .swiperContainer, .innerContainer {
	height: 100% !important;
  }
  .component-carousel {
	color: white;
  }
  .component-carousel .innerContainer {
	text-align: center;
	max-width: 690px;
	margin: 0 auto;
	padding: 40px 0;
  }
  .component-carousel .innerContainer > div:nth-child(2) {
	position: absolute;
	padding: 0 20px;
	max-width: 690px;
  }
  @media only screen and (max-height: 569px) {
	.component-carousel .innerContainer > div:nth-child(2) {
	  bottom: 11%;
	}
  }
  @media only screen and (min-height: 570px) and (max-height: 812px) {
	.component-carousel .innerContainer > div:nth-child(2) {
	  bottom: 13%;
	}
  }
  @media only screen and (min-height: 813px) {
	.component-carousel .innerContainer > div:nth-child(2) {
	  bottom: 15%;
	}
  }
  .component-carousel .logo {
	margin-bottom: 40px;
  }
  @media only screen and (min-width: 600px) {
	.component-carousel .logo {
	  margin-bottom: 40px;
	  width: 250px;
	}
  }
  .component-carousel .illustration {
	width: 75%;
	margin-bottom: 5%;
	object-fit: contain;
	max-height: 300px;
  }
  @media only screen and (min-height: 800px) {
	.component-carousel .illustration {
	  max-height: 550px;
	}
  }
  @media only screen and (min-height: 1024px) {
	.component-carousel .illustration {
	  margin-top: 5%;
	}
  }
  .component-carousel .title {
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.18px;
	margin-bottom: 14px;
	font-weight: 400;
  }
  @media only screen and (min-width: 600px) {
	.component-carousel .title {
	  font-size: 34px;
	  line-height: 36px;
	}
  }
  .component-carousel .description {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
	opacity: 0.7;
	padding: 0 5px;
  }
  @media only screen and (min-width: 600px) {
	.component-carousel .description {
	  font-size: 16px;
	  line-height: 24px;
	}
  }
  .component-carousel .swiperContainer {
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
  }
  .component-carousel .swiper {
	right: 0;
	top: 0;
	height: 100%;
  }
  .component-carousel .nextButton {
	top: 55%;
	right: 15%;
	position: absolute;
	width: 60%;
  }
  .listContainer {
	position: absolute;
	display: flex;
	width: 100%;
	justify-content: center;
	bottom: 4%;
  }
  .listContainer .list {
	list-style-type: none;
	display: flex;
	padding: 0;
  }
  .listContainer .list .listItem {
	border: 0.5px solid;
	border-radius: 50%;
	background-color: transparent;
	padding: 5px;
	vertical-align: middle;
	cursor: pointer;
  }
  .listContainer .link {
	position: absolute;
	right: 4%;
	text-decoration: none;
	padding-right: 10px;
  }
  
.component-mainMenu .sectionContainer {
	max-width: 1140px;
	background-color: white;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
	padding: 16px 16px 0px 16px;
	margin: 0 auto 24px auto;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer {
	  padding: 24px 24px 0px 24px;
	}
  }
  .component-mainMenu .sectionContainer .titleContainer {
	display: flex;
  }
  .component-mainMenu .sectionContainer .titleContainer .icon {
	width: 14px;
	height: 14px;
	object-fit: contain;
	margin-right: 8px;
  }
  .component-mainMenu .sectionContainer .titleContainer .title {
	font-size: 10px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 1.5px;
	color: #939799;
  }
  .component-mainMenu .sectionContainer .cardsContainer {
	display: flex;
	flex-direction: column;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer {
	  display: grid;
	  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
  }
  .component-mainMenu .sectionContainer .cardsContainer > .card:not(:last-child) {
	border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  }
  .component-mainMenu .sectionContainer .cardsContainer .card {
	display: flex;
	padding: 16px 0;
	cursor: pointer;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer .card {
	  flex-direction: column;
	  align-items: center;
	  text-align: center;
	}
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .imageContainer {
	position: relative;
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .imageContainer .image {
	height: 50px;
	object-fit: contain;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer .card .imageContainer .image {
	  height: 115px;
	  margin-bottom: 8px;
	}
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .imageContainer .tooltip {
	position: absolute;
	right: -5px;
	top: -5px;
	font-size: 10px;
	font-weight: 500;
	line-height: 1.6;
	background-color: #c8193c;
	border-radius: 50%;
	width: 15px;
	height: 15px;
	text-align: center;
	color: white;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer .card .imageContainer .tooltip {
	  width: 32px;
	  height: 32px;
	  font-size: 20px;
	}
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .textContainer {
	margin-left: 20px;
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer .card .textContainer {
	  margin-left: 0;
	}
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .textContainer .title {
	font-size: 16px;
	line-height: 1.5;
	font-weight: normal;
	letter-spacing: 0.5px;
	color: rgba(0, 0, 0, 0.87);
  }
  .component-mainMenu .sectionContainer .cardsContainer .card .textContainer .description {
	font-size: 14px;
	line-height: 1.43;
	letter-spacing: 0.25px;
	color: rgba(0, 0, 0, 0.6);
  }
  @media only screen and (min-width: 960px) {
	.component-mainMenu .sectionContainer .cardsContainer .card .textContainer .description {
	  padding: 0 8px;
	}
  }
  .component-mainMenu .sectionContainer .linksContainer .link {
	font-size: 16px;
	line-height: 1.5;
	font-weight: normal;
	letter-spacing: 0.5px;
	color: rgba(0, 0, 0, 0.87);
	padding: 12px 0;
	text-decoration: none;
	display: block;
  }
  .component-mainMenu .sectionContainer .linksContainer > .link:not(:last-child) {
	border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  }
  
